export default [{
  name: 'Chris Gray',
  status: 'success',
  lastMessage: 'Hey! What\'s up? So many times since we',
  image: '1.jpg',
  messages: [{
    id: 0,
    text: 'Hey! What\'s up?',
  }, {
    id: 1,
    text: 'Are you there?',
  }, {
    id: 2,
    text: 'Let me know when you come back.',
  }, {
    id: 3,
    text: 'I am here!',
    fromMe: true,
  }],
}, {
  name: 'Jamey Brownlow',
  status: 'gray-light',
  lastMessage: 'Good news coming tonight. Seems they agreed to proceed',
  image: '2.jpg',
  messages: [{
    id: 0,
    text: 'Good news coming tonight',
  }, {
    id: 1,
    text: 'Seems they agreed to proceed',
  }, {
    id: 2,
    text: 'Let me know when you come back.',
  }, {
    id: 3,
    text: 'I am here!',
    fromMe: true,
  }],
}, {
  name: 'Livia Walsh',
  status: 'danger',
  lastMessage: 'Check out my latest email plz!',
  image: '3.jpg',
}, {
  name: 'Jaron Fitzroy',
  status: 'gray-light',
  lastMessage: 'What about summer break?',
  image: '4.jpg',
}, {
  name: 'Mike Lewis',
  status: 'success',
  lastMessage: 'Just ain\'t sure about the weekend now. 90% I\'ll make it.',
  image: '5.jpg',
}]
